var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('validation-observer',{ref:"verificationRules"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationFormVirefy($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',{staticClass:"text-h4 mb-1"},[_vm._v("التحقق المكون من خطوتين 💬")]),_c('p',{staticClass:"mb-2"},[_vm._v(" تم إرسال رمز التحقق إلى "+_vm._s(_vm.formData.authentcate_by == "phone" ? " هاتفك المحمول." : "البريد الالكتروني")+" يُرجى إدخال الرمز المرسل من "+_vm._s(_vm.formData.authentcate_by == "phone" ? "الهاتف " : "البريد")+" في الحقل أدناه. ")]),(!_vm.change_phone)?_c('div',[(_vm.type_registration == 'temp')?_c('h6',{staticClass:"text-base font-weight-medium",staticStyle:{"direction":"ltr"}},[_vm._v(" "+_vm._s(_vm.formData.authentcate_by == "phone" ? _vm.formData.phone : _vm.formData.email)+" ")]):_c('h6',{staticClass:"text-base font-weight-medium",staticStyle:{"direction":"ltr"}},[_vm._v(" "+_vm._s(_vm.formData.authentcate_by == "phone" ? _vm.patternPhone : _vm.formData.email)+" ")])]):_vm._e(),(_vm.formData.authentcate_by == 'phone')?_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"change_phone","name":"change_phone"},model:{value:(_vm.change_phone),callback:function ($$v) {_vm.change_phone=$$v},expression:"change_phone"}},[_vm._v(" تغيرر رقم الجوال ")])],1):_vm._e(),(_vm.change_phone)?_c('b-form-group',{attrs:{"label":"رقم الجوال","label-for":"phone"}},[_c('validation-provider',{ref:"phoneFieldValidator",attrs:{"name":"phone","rules":_vm.rulesPhone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"mode":"national","autoFormat":false,"validCharactersOnly":true,"defaultCountry":_vm.defaultCountry,"onlyCountries":_vm.countres,"invalidMsg":errors[0],"dropdownOptions":{
                      disabled: false,
                      tabindex: 0,
                      showDialCodeInSelection: true,
                      showDialCode: true,
                      showFlags: true
                    },"inputOptions":{
                      autocomplete: 'off',
                      readonly: false,
                      maxlength: 12,
                      name: 'phone',
                      id: 'phone',
                      type: 'tel',
                      required: _vm.formData.authentcate_by == 'phone',
                      placeholder: 'رقم التلفون '
                    },"name":"phone","wrapperClasses":"phone-wrapper","placeholder":"رقم التلفون "},on:{"validate":_vm.updateFormData,"country-changed":_vm.updateFormData},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(ref){
                    var open = ref.open;
return [_c('span',{staticClass:"vti__dropdown-arrow"},[_vm._v(_vm._s(open ? "▲" : "▼"))])]}}],null,true),model:{value:(_vm.formData.phone_number),callback:function ($$v) {_vm.$set(_vm.formData, "phone_number", $$v)},expression:"formData.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1662608520)})],1):_vm._e(),_c('h6',{staticClass:"text-base font-weight-medium mt-3"},[_vm._v("الرجاء إدخال رمز الأمان المكون من 6 أرقام.")])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"verificationCode"}},[_c('validation-provider',{attrs:{"name":"كود التحقق","vid":"verificationCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('SimpleOtpInput',{attrs:{"name":"verificationCode","length":6,"type":"number","inputProps":{
                      disabled: _vm.disableButton,
                      type: 'number',
                      pattern: '[0-9]'
                    }},on:{"complete":_vm.handleComplete},model:{value:(_vm.verifyForm.verification_code),callback:function ($$v) {_vm.$set(_vm.verifyForm, "verification_code", $$v)},expression:"verifyForm.verification_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"warning","type":"submit","block":"","disabled":_vm.disableVerfiyButton}},[(_vm.disableVerfiyButton)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" تحقق ")],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"justify-content-center text-center mt-2 flex-wrap"},[(_vm.timerOn && _vm.timerMsg)?_c('div',{staticClass:"red"},[_c('br'),_vm._v(" "+_vm._s(_vm.timerMsg)+" ")]):_vm._e(),(_vm.timerOn)?_c('div',{staticClass:"content_timer"},[_vm._v(" وقت الانتظار... "),_c('span',{attrs:{"id":"timer"}},[_vm._v(_vm._s(_vm.formattedTime)+" دقيقة ")])]):_vm._e(),(!_vm.timerOn)?[_c('span',{staticClass:"mr-1"},[_vm._v("لم تستلم الرمز؟ ")]),_c('b-button',{attrs:{"variant":"link","disabled":_vm.disableButton},on:{"click":_vm.reSendCode}},[_vm._v(" إعادة الإرسال ")])]:_vm._e()],2)])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }