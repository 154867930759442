<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <validation-observer ref="verificationRules">
          <b-form class="auth-login-form mt-2" @submit.prevent="validationFormVirefy">
            <b-row>
              <b-col md="12">
                <h4 class="text-h4 mb-1">التحقق المكون من خطوتين 💬</h4>
                <p class="mb-2">
                  تم إرسال رمز التحقق إلى
                  {{ formData.authentcate_by == "phone" ? " هاتفك المحمول." : "البريد الالكتروني" }}
                  يُرجى إدخال الرمز المرسل من
                  {{ formData.authentcate_by == "phone" ? "الهاتف " : "البريد" }}
                  في الحقل أدناه.
                </p>
                <div v-if="!change_phone">
                  <h6 class="text-base font-weight-medium" v-if="type_registration == 'temp'" style="direction: ltr">
                    {{ formData.authentcate_by == "phone" ? formData.phone : formData.email }}
                  </h6>
                  <h6 class="text-base font-weight-medium" v-else style="direction: ltr">
                    {{ formData.authentcate_by == "phone" ? patternPhone : formData.email }}
                  </h6>
                </div>

                <b-form-group v-if="formData.authentcate_by == 'phone'">
                  <b-form-checkbox id="change_phone" v-model="change_phone" name="change_phone">
                    تغيرر رقم الجوال
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group v-if="change_phone" label="رقم الجوال" label-for="phone">
                  <validation-provider #default="{ errors }" name="phone" ref="phoneFieldValidator" :rules="rulesPhone">
                    <vue-tel-input
                      v-model="formData.phone_number"
                      mode="national"
                      :autoFormat="false"
                      :validCharactersOnly="true"
                      :defaultCountry="defaultCountry"
                      :onlyCountries="countres"
                      :invalidMsg="errors[0]"
                      :dropdownOptions="{
                        disabled: false,
                        tabindex: 0,
                        showDialCodeInSelection: true,
                        showDialCode: true,
                        showFlags: true
                      }"
                      :inputOptions="{
                        autocomplete: 'off',
                        readonly: false,
                        maxlength: 12,
                        name: 'phone',
                        id: 'phone',
                        type: 'tel',
                        required: formData.authentcate_by == 'phone',
                        placeholder: 'رقم التلفون '
                      }"
                      name="phone"
                      @validate="updateFormData"
                      @country-changed="updateFormData"
                      wrapperClasses="phone-wrapper"
                      placeholder="رقم التلفون "
                    >
                      <template #arrow-icon="{ open }">
                        <span class="vti__dropdown-arrow">{{ open ? "▲" : "▼" }}</span>
                      </template>
                    </vue-tel-input>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <h6 class="text-base font-weight-medium mt-3">الرجاء إدخال رمز الأمان المكون من 6 أرقام.</h6>
              </b-col>
              <!-- <otp-input-code :pin="verificationCode" @restPin="newVale=>verificationCode=newVale" /> -->
              <b-col md="12">
                <b-form-group label-for="verificationCode">
                  <validation-provider #default="{ errors }" name="كود التحقق" vid="verificationCode" rules="required">
                    <SimpleOtpInput
                      name="verificationCode"
                      :length="6"
                      type="number"
                      :inputProps="{
                        disabled: disableButton,
                        type: 'number',
                        pattern: '[0-9]'
                      }"
                      v-model="verifyForm.verification_code"
                      @complete="handleComplete"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <!-- submit button -->
                <b-button variant="warning" type="submit" block :disabled="disableVerfiyButton">
                  <b-spinner small type="grow" v-if="disableVerfiyButton"></b-spinner>
                  تحقق
                </b-button>
              </b-col>
            </b-row>

            <b-col md="12">
              <div class="justify-content-center text-center mt-2 flex-wrap">
                <div v-if="timerOn && timerMsg" class="red">
                  <br />
                  {{ timerMsg }}
                </div>
                <div class="content_timer" v-if="timerOn">
                  وقت الانتظار...
                  <span id="timer">{{ formattedTime }} دقيقة </span>
                </div>
                <template v-if="!timerOn">
                  <span class="mr-1">لم تستلم الرمز؟ </span>
                  <b-button variant="link" @click="reSendCode" :disabled="disableButton">
                    <!--   <feather-icon icon="RepeatIcon" /> -->
                    إعادة الإرسال
                  </b-button>
                </template>
              </div>
            </b-col>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";
//import VuexyLogo from '@core/layouts/components/Logo.vue'
import { utilsMixin } from "@core/mixins/AuthForm/index.js";
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BLink,
  BRow,
  BSpinner
} from "bootstrap-vue";
import SimpleOtpInput from "vue-simple-otp-input";
import "vue-simple-otp-input/dist/vue-simple-otp-input.css";
import useJwt from "@/auth/jwt/useJwt";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
//import "vue-tel-input/vue-tel-input.css";

import "./style.css";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BCardTitle,
    BCardText,
    BFormCheckbox,
    BFormInvalidFeedback,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SimpleOtpInput
  },
  //   props: {
  //     form_type: null,
  //     token: null,
  //     type_registration: null,
  //     formData: {
  //       type: Object,
  //       required: true,
  //     },
  //     countres: {
  //       type: Array,
  //       default: [],
  //     },
  //   },
  data() {
    return {
      userEmail: "",
      //  sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      current_component: "verify",
      verifyForm: {
        verification_code: "",
        activation_token: ""
      },
      timerOn: true,
      timerMsg: "",
      remainingTime: 120,
      formattedTime: "02:00" // Initial value
    };
  },
  mixins: [utilsMixin],
  computed: {
    formData() {
      return this.$store.state.auth.dataAuth;
      // return this.$store.getters['auth/phoneObject'];
    },
    urlApiVirefy() {
      if (this.form_type == "register" && this.formData.type_registration == "temp") {
        return "verify/validate";
      }
      // return this.form_type=="login"?'verify/tologin':'verify/validate';
      return "email/verify/check";
    },
    urlApiResendVirefy() {
      if (this.form_type == "register" && this.formData.type_registration == "temp") {
        return "verify/resend/temp";
      }
      return "email/resend";
    },
    patternPhone() {
      return this.formData.phone.replace(/\d(?=\d{4})/g, "*");
    },
    rulesPhone() {
      return this.formData.authentcate_by === "phone" ? "required|phone_validate" : undefined;
    }
  },
  mounted() {
    const token = this.$route.params.verfiy_token;
    if (token) {
      this.verifyForm.activation_token = token;
    }
    this.settingTimer();
  },
  methods: {
    getTokens() {
      const token = this.$route.params.verfiy_token;
      this.$http
        .get(`password-reset-check/${token}`)
        .then(response => {
          if (response.data.status === true) {
            this.user = response.data.result;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.showToast(response.data.msg, "danger");
            setTimeout(() => {
              this.$router.push({ name: "misc-error-404" });
            }, 500);
          }
        })
        .catch(response => {
          this.isLoading = false;
          this.showToast(response.data.msg, "danger");
          setTimeout(() => {
            this.$router.push({ name: "misc-error-404" });
          }, 500);
        });
    },
    settingTimer() {
      const timerInterval = setInterval(() => {
        if (this.remainingTime > 0 && this.timerOn) {
          this.remainingTime -= 1;
          this.formatTime();
        } else {
          clearInterval(timerInterval);
          this.timerOn = false;
          this.timerMsg = "";
          if (!this.timerOn) {
            // Do validate stuff here
            return;
          }
        }
      }, 1000);
    },
    formatTime() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;

      this.formattedTime = (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    },
    handleComplete(data) {
      // console.log('data',data)
      this.sendVirefy();
    },
    sendVirefy() {
      let formData = {
        ...this.verifyForm,
        ...this.authData
        // ...this.phoneObject
      };

      if (this.formData.authentcate_by == "phone") {
        formData = this.getFormData(formData);
      }

      this.disableButton = true;
      this.disableVerfiyButton = true;
      this.$http
        .post(`${this.prefixApi}/${this.urlApiVirefy}`, formData)
        .then(response => {
          let data = response.data;
          this.disableButton = false;
          this.disableVerfiyButton = false;

          if (data.access_token) {
            this.disableButton = true;
            this.disableVerfiyButton = true;
            let accessToken = response.data.access_token;
            let userData = response.data.user;

            useJwt.setToken(response.data.access_token);
            useJwt.setRefreshToken(response.data.access_token);
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("accessToken", accessToken);
            this.$router.push({ name: "dashboard.index" });
            // window.location.href = data.url;
          } else {
            if (data.token) {
              this.verifyForm.activation_token = data.token;
            }
            if (data.type) {
              // this.$emit("updateRegisterType", data.type);
            }
            if (this.form_type == "register" && data.step && data.step == "complete_profile") {
              var dataProfile = {
                ...this.formData,
                ...this.verifyForm,
                ...this.phoneObject,
                ...data
              };
              if (data.type) {
                //  this.$emit("updateRegisterType", data.type);
                dataProfile.form_type = data.type;
              }
              //  this.$emit("updateVerifyForm", dataProfile);
              //  this.$emit("updateTabIndex", 2);

              this.$store.dispatch("setDataAuth", dataProfile);
            }
          }

          if (response.data.status === true && response.data.step != "login") {
            this.showToast(response.data.msg, "succcess");
            // this.disableButton = false
            this.$router.push({ name: "complete-profile" });
          } else {
            this.showToast(response.data.msg, "danger");
          }
        })
        .catch(error => {
          // console.error(error)
          this.disableButton = false;
          this.disableVerfiyButton = false;
          let result = error.response;
          if (error.response && error.response.data) {
            result = error.response.data;
          }
          if (result.step_to_register) {
            this.restAll(this.form_type);
            this.$emit("restAll", this.form_type);
          }

          this.errorsHandler(error, "verificationRules");
          if (error.errors) {
            //  self.errorsres = error.errors;
            this.$refs.verificationRules.setErrors(error.errors);
          }
          //  this.showToast(this.$t('messages.error_found'), 'danger')
        });
    },

    validationFormVirefy() {
      return new Promise((resolve, reject) => {
        this.$refs.verificationRules.validate().then(success => {
          if (success) {
            this.sendVirefy();
          } else {
            this.showToast("يجب التحقق من الحقول", "danger");
            reject();
          }
        });
      });
    },
    reSendCode() {
      if (this.disableButton == true) {
        return false;
      }
      this.verifyForm.verification_code = "";
      let formData = {
        ...this.formData,
        ...this.verifyForm
        // ...this.phoneObject
      };

      if (this.formData.authentcate_by == "phone") {
        formData = this.getFormData(formData);
      }

      if (this.formData.form_type == "login") {
      } else {
        formData.type_registration = this.type_registration;
      }

      this.disableButton = true;
      this.disableVerfiyButton = true;
      this.$http
        .post(`${this.prefixApi}/${this.urlApiResendVirefy}`, formData)
        .then(response => {
          let data = response.data;

          if (data.token) {
            this.verifyForm.activation_token = data.token;
          }

          this.remainingTime = 120;
          if (data.timeDelay) {
            this.remainingTime = data.timeDelay;
          }
          // Reset remaining time
          this.timerOn = true;
          this.settingTimer();
          this.disableVerfiyButton = false;
          this.disableButton = false;

          if (response.data.status === true) {
            this.showToast(response.data.msg, "succcess");
            // this.disableButton = false
            // this.$router.push({ name: 'auth-login' })
          } else {
            this.showToast(response.data.msg, "danger");
          }
        })
        .catch(error => {
          // console.error(error)
          let result = error.response;
          if (error.response && error.response.data) {
            result = error.response.data;
          }
          if (result.error_type) {
            this.remainingTime = result.timeDelay ? result.timeDelay : 120;
            if (this.remainingTime > 0) {
              this.timerOn = true;
              this.settingTimer();
            }

            if (result.msg && result.msg.length > 0) {
              this.timerMsg = result.msg;
            }
          }

          // Reset remaining time

          this.disableVerfiyButton = false;
          this.disableButton = false;
          this.errorsHandler(error, "verificationRules");
        });
    }
  }
};
</script>
<style lang="scss">
@import "~@resources/scss/vue/pages/page-auth.scss";
</style>
